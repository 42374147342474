<template>
  <div class="page">
    <div class="page-content">
      <div class="login-container">
        <div class="logo-container">
          <img src="@/assets/lifebloom-logo-with-name-white.png" alt="ligebloom-logo" />
        </div>

        <h2>Back Office</h2>

        <a :href="microsoftConnectLink" class="microsoft-connect">
          <SVGMicrosoftConnect />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SVGMicrosoftConnect from '@/assets/microsoft-connect.svg'

export default {
  name: 'Login',
  components: {
    SVGMicrosoftConnect
  },
  created () {
    if (this.$auth.isLogged()) {
      this.$router.push({ name: 'ExercisesList' })
    }
  },
  computed: {
    microsoftConnectLink () {
      const params = {
        client_id: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
        response_type: 'code',
        redirect_uri: window.location.href + 'login/microsoft',
        response_mode: 'fragment',
        scope: 'User.Read%20Group.Read.All',
        state: Date.now()
      }

      const formatedParams = Object.keys(params).map(k => {
        if (params[k] instanceof Array) {
          return params[k].map(x => `${k}=${x}`).join('&')
        } else {
          return `${k}=${params[k]}`
        }
      }).join('&')

      return 'https://login.microsoftonline.com/lifebloom.eu/oauth2/v2.0/authorize?' + formatedParams
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
  background:
    linear-gradient(132deg, #091B62 5%, rgba(12, 36, 134, 0) 96.16%),
    linear-gradient(0deg, #0D229C, #0D229C);
  height: 100vh;
  color: $white-color;

  .page-content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .login-container{
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo-container {
        width: 155px;
        margin: 64px auto 20px;

        img{
          width: 100%;
        }
      }

      h2{
        font-size: 1.75rem;
        letter-spacing: .22em;
        text-transform: uppercase;
        font-weight: 600;
        margin: 24px 0;
      }

      .microsoft-connect{
        margin-top: 50px;
      }
    }
  }
}
</style>
